@media screen and (min-width: 768px) {
  .landing-page {
    max-width: 100vw; /* Limits width to 40% of viewport */
    height: 100vh;
  }
  .landing-page-content {
    /* width: 40vw; Remove this line */
    max-width: 30em; /* Limits width to 40% of viewport */
    height: auto;
  }
  .text-block {
    max-width: 25em; /* You can adjust this value for better text flow */
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .text-block {
    max-width: 90vw;
    height: auto;
  }
}